import React, { useEffect } from "react";
import 'animate.css';
import WOW from 'wowjs';
import BoardCard from './BoardCard';
import './SuperHeroes.css'
import { Container, Row, Col } from 'react-bootstrap';
import member1 from './assets/images/square-image.jpg'




function PastPresident() {


    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
      }, []);
    const members = [
        { name: '######', title: '#####', image: member1 },
        { name: '######', title: '#####', image: member1 },
        { name: '######', title: '#####', image: member1 },
        { name: '######', title: '#####', image: member1 },
        { name: '######', title: '#####', image: member1 },
        { name: '######', title: '#####', image: member1 },
        { name: '######', title: '#####', image: member1 },
        { name: '######', title: '#####', image: member1 },






        // Add more members as needed
    ];

    return (
        <Container className='zone-container text-center'>
            <h1>Past Presidents</h1>
            <Row>
                {members.map((member, index) => (
                    <Col xs={6} sm={6} lg={3} key={index} className="wow animate__animated animate__fadeIn">
                        <BoardCard name={member.name} title={member.title} image={member.image} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default PastPresident;
