import React, { useEffect } from "react";
import 'animate.css';
import WOW from 'wowjs';
import BoardCard from './BoardCard';
import './SuperHeroes.css'
import { Container, Row, Col } from 'react-bootstrap';
import member1 from './assets/images/member-one.png'
import president from './assets/images/president-card.png'
import ladyjc from './assets/images/vishita.png';
import youngcm from './assets/images/aryan.png';

// import member2 from './assets/images/member-2.png'
import member3 from './assets/images/member-3.png'
import member4 from './assets/images/member-4.png'
import member5 from './assets/images/member-5.png'
import member6 from './assets/images/member-6.png'
import member7 from './assets/images/member-7.png'
import member8 from './assets/images/member-8.png'
import member9 from './assets/images/member-9.png'
import member10 from './assets/images/member-10.png'
import member11 from './assets/images/member-11.png'
import member12 from './assets/images/member-12.png'
import member13 from './assets/images/member-13.png'
import member14 from './assets/images/member-14.png'
import member15 from './assets/images/member-15.png'
import member16 from './assets/images/member-16.png'


import member17 from './assets/images/nileshdesai.png';
import member18 from './assets/images/prasannagandhi.png';
import member19 from './assets/images/vishalwankhade.png';
import member20 from './assets/images/romitparekh.png';
import member21 from './assets/images/darshanmundhada.png';
import member22 from './assets/images/amanshahu.png';
import member23 from './assets/images/pratikgawande.png';






function SuperHeroes() {


    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
      }, []);
    const members = [
        { name: 'JCI senetor Deepak Lokhande', title: 'President', image: president },

        { name: 'JFM Ankit Purwar', title: 'Secretary', image:member6 },
        { name: 'JFM Satish Kadu', title: 'IPP', image:member16 },
        { name: 'JFM Vishita Samdariya', title: 'Lady Jaycee', image:ladyjc },
        { name: 'JFM Aryan Behare', title: 'Young Wing Chairman', image:youngcm },


        { name: 'JFM Amol Zanwar', title: 'Treasurer', image:member3 },
        { name: 'JC HGF Anil Patel', title: 'VP LO', image:member4 },
        { name: 'JC Mukesh Ferwani', title: 'VP ID', image:member7 },
        { name: 'JC JFD Atul Lawange', title: 'VP Community', image:member5 },

        { name: 'JC Nagraj Patil', title: 'VP Business', image:member10 },
        { name: 'JC Sachin Shahakar', title: 'VP int. and G&D', image:member15 },
        { name: 'JC Dhananjay Shinde', title: 'Director LO', image:member1 },
        { name: 'JC Dhanananjay Bhakre', title: 'Joint Treasurer', image:member13 },

        { name: 'JC Saurabh Kakani', title: 'Director Training', image:member12 },
        { name: 'JC Akhilesh Rathi', title: 'Director international and G&D', image:member8 },

        { name: 'JC HGF Himanshoo Agarwal', title: 'Director Business', image:member9 },
        { name: 'JC Prakalp Chandak', title: 'Joint Secretary', image:member14 },
        { name: 'JC Bhushan Parihar', title: 'Director Community', image:member11 },
        { name: 'JC Nilesh Desai', title: 'Empowering Youth', image:member17 },
        { name: 'JC Prasana Gandhi', title: 'Impact 2030', image:member18 },
        { name: 'JC Vishal Wankhade', title: 'Food & Venue', image:member19 },
        { name: 'JC Romit Parekh', title: 'Bulletin Editor', image:member20 },
        { name: 'JC Darshan Mundhada', title: 'Co-Editor', image:member21 },
        { name: 'JC HGF Aman Shahu', title: 'PRO and website Handling', image:member22 },
        { name: 'JC Pratik Gawande', title: 'PRO and website Handling', image:member23 },










        // { name: 'JFS Jayesh Pampalia', title: 'member2', image:member2 },




        // Add more members as needed
    ];

    return (
            <Container className="zone-container text-center">
                <h1>JCI Amravati 2025</h1>
                <Row className="justify-content-center">
                    {/* Render the first two members in the center */}
                    {members.slice(0, 2).map((member, index) => (
                        <Col xs={6} sm={6} lg={4} key={index} className="wow animate__animated animate__fadeIn">
                            <BoardCard name={member.name} title={member.title} image={member.image} />
                        </Col>
                    ))}
                </Row>
                {/* Render the rest of the members */}
                <Row>
                    {members.slice(2).map((member, index) => (
                        <Col xs={6} sm={6} lg={3} key={index} className="wow animate__animated animate__fadeIn">
                            <BoardCard name={member.name} title={member.title} image={member.image} />
                        </Col>
                    ))}
                </Row>
            </Container>

    );
}

export default SuperHeroes;
