import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './../node_modules/bootstrap/dist/js/bootstrap.min.js';
import 'animate.css';

import TopContainer from './components/TopContainer';
import CarouselContainer from './components/CarouselContainer';
import FeaturesContainer from './components/FeaturesContainer';
import JciContainer from './components/JciContainer';
import JoinContainer from './components/JoinContainer';
import PcContainer from './components/PcContainer';
import WhatContainer from './components/WhatContainer';
import YtContainer from './components/YtContainer';
import GalleryContainer from './components/GalleryContainer';
import ProgramsContainer from './components/ProgramsContainer';
import TagContainer from './components/TagContainer';
import Footer from './components/Footer';
import About from './components/About';
import Gallery from './components/Gallery';
import Form from './components/Form';

import AboutJCI from './components/AboutJCI';
import SuperHeroes from './components/SuperHeroes.js';
import PastPresidents from './components/PastPresidents.js';

import ZoneBoard from './components/ZoneBoard.js';
import JciMembers from './components/JciMembers.js';





const Layout = ({ children }) => (
  <>
  <TopContainer />
  <div className="main-content">
    {children}
  </div>
  <Footer />
  </>
);

const App = ()  => {
  return (
    <Router>


      <Layout>

      <Routes>
          <Route path="/" element={
            <>
            <CarouselContainer/>
            <FeaturesContainer />
            <JciContainer />
            <JoinContainer />
            <PcContainer />
            <WhatContainer />
            <ProgramsContainer />
            <GalleryContainer />
            <YtContainer />
            <TagContainer />

            
            </>

          } />

          <Route path="/About" element={<About/>} />
          <Route path="/AboutJCI" element={<AboutJCI/>} />


          <Route path="/Members 2025" element={<SuperHeroes/>} />
          <Route path="/Past Presidents" element={<PastPresidents/>} />
          <Route path="/JciMembers" element={<JciMembers/>} />

          <Route path="/" element={<ZoneBoard/>} />
          <Route path="/Gallery" element={<Gallery/>} />
          <Route path="/Form" element={<Form/>} />






        </Routes>

      </Layout>



    </Router>
  );
};

export default App;
